import * as L from 'leaflet';
import { ViewPortBounds } from './typings';
import { LatLngExpression } from 'leaflet';

export const tooltipOffset = {
    x: 0,
    y: -25,
};

export function getTilesUrlTemplate(): string {
    const baseTilesUrl = process.env.REACT_APP_TILES_URL || '//{s}.tile.openstreetmap.org';
    return `${baseTilesUrl}/{z}/{x}/{y}.png`;
}

export const TILE_LAYER_ATTRIBUTION = '&copy <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';

export const customIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

export enum MapNames {
    CASH_BOXES = 'cashBoxesMap',
    STORES = 'storesMap',
}

export const BAKU_CENTER: LatLngExpression = [40.409264, 49.867092];

export const DEFAULT_ZOOM = 12;

export const BAKU_BOUNDS: ViewPortBounds = {
    lowerLeftLat: 40.309116232201845,
    lowerLeftLon: 50.01319885253906,
    upperRightLat: 40.50936182187013,
    upperRightLon: 49.630393981933594,
};

export const mapOptions: L.MapOptions = {
    zoomControl: false,
    maxZoom: 18,
    minZoom: 3,
    worldCopyJump: true,
    maxBounds: [
        [-90, -180],
        [90, 180],
    ],
    maxBoundsViscosity: 1.0,

    zoomAnimation: false,
    fadeAnimation: false,
    trackResize: true,
    markerZoomAnimation: false,
    dragging: true,
    preferCanvas: true,
    renderer: L.canvas(),
};
