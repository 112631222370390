import { Viewport } from '../../components/Map/typings';
import { BAKU_BOUNDS, BAKU_CENTER, DEFAULT_ZOOM } from '../../components/Map/consts';
import { CashBoxesMapAction, CashBoxesMapActionType } from '../actions/ACashBoxesMap';
import { CashBoxesMap, CashBoxesMapFilterParams } from '../api/cashBoxes/cashBoxesClient.typings';
import { defaultCashBoxesStatusesFilter } from './CashBoxRegistry/cashBoxesReducer';

const defaultSearchType = 'cashBoxTaxNumbers';

export interface CashBoxesMapState {
    viewport: Viewport;
    filterParams: CashBoxesMapFilterParams;
    changedFilterParams: CashBoxesMapFilterParams;
    cashBoxes: CashBoxesMap;
    isLoading: boolean;
}

const initialState: CashBoxesMapState = {
    viewport: {
        bounds: {
            ...BAKU_BOUNDS,
        },
        center: BAKU_CENTER,
        zoom: DEFAULT_ZOOM,
    },
    filterParams: {
        search_type: defaultSearchType,
        search_text: '',
        afterKeyId: null,
        limit: 10000,
        cashBoxStatuses: defaultCashBoxesStatusesFilter,
    },
    changedFilterParams: null,
    cashBoxes: null,
    isLoading: false,
};

export function cashBoxesMapReducer(
    state: CashBoxesMapState = initialState,
    action: CashBoxesMapAction,
): CashBoxesMapState {
    switch (action.type) {
        case CashBoxesMapActionType.OPEN_CASH_BOXES_MAP_PAGE:
            return {
                ...state,
                isLoading: true,
            };

        case CashBoxesMapActionType.CASH_BOXES_VIEWPORT_CHANGE:
            return {
                ...state,
                viewport: action.payload,
                isLoading: state.filterParams.search_text === '',
            };

        case CashBoxesMapActionType.APPLY_FILTERS:
            return {
                ...state,
                filterParams: state.changedFilterParams ? { ...state.changedFilterParams } : { ...state.filterParams },
                changedFilterParams: null,
                isLoading: true,
            };

        case CashBoxesMapActionType.CHANGE_FILTERS:
            return {
                ...state,
                changedFilterParams: {
                    ...state.filterParams,
                    ...action.payload,
                },
            };

        case CashBoxesMapActionType.FETCH_CASH_BOXES_SUCCESS:
            return {
                ...state,
                cashBoxes: action.payload,
                isLoading: false,
            };

        case CashBoxesMapActionType.FETCH_CASH_BOXES_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        case CashBoxesMapActionType.CLOSE_CASH_BOXES_MAP_PAGE:
            return {
                ...state,
                cashBoxes: null,
            };

        default:
            break;
    }
    return state;
}
