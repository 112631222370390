import { Epic, ofType } from 'redux-observable';
import { RootState } from '../store.typings';
import {
    CashBoxesMapAction,
    CashBoxesMapActionType,
    fetchCashBoxesErrorAction,
    fetchCashBoxesSuccessAction,
} from '../actions/ACashBoxesMap';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { cashboxesClient } from '../api/cashBoxes/cashboxesClient';
import { AxiosError } from 'axios';
import { parseError } from '../../utils/utils';
import { i18n } from '../translation/i18n';
import { setZoomLevel } from '../../components/Map/utils';

type CashBoxesMapEpic = Epic<CashBoxesMapAction, CashBoxesMapAction, RootState>;

export const fetchCashBoxesForMapEpic: CashBoxesMapEpic = (action$, state) => {
    return action$.pipe(
        ofType(
            CashBoxesMapActionType.OPEN_CASH_BOXES_MAP_PAGE,
            CashBoxesMapActionType.APPLY_FILTERS,
            CashBoxesMapActionType.CASH_BOXES_VIEWPORT_CHANGE,
        ),
        filter((action) => {
            const filterParams = state.value.cashBoxesMapReducer.filterParams;

            return (
                filterParams.search_text === '' ||
                (filterParams.search_text !== '' &&
                    (action.type === CashBoxesMapActionType.APPLY_FILTERS ||
                        action.type === CashBoxesMapActionType.OPEN_CASH_BOXES_MAP_PAGE))
            );
        }),
        exhaustMap(() => {
            const viewport = state.value.cashBoxesMapReducer.viewport;
            const filterParams = state.value.cashBoxesMapReducer.filterParams;
            const zoom = setZoomLevel(viewport.zoom);

            const params = {
                filterParams,
                viewport: {
                    ...viewport,
                    zoom,
                },
            };

            if (filterParams.search_text !== '') {
                delete params.viewport.bounds;
                delete params.viewport.zoom;
            }

            return from(cashboxesClient.getCashBoxesForMap(params)).pipe(
                map((cashBoxes) => fetchCashBoxesSuccessAction(cashBoxes)),

                catchError((err: AxiosError) =>
                    of(
                        fetchCashBoxesErrorAction(
                            parseError(err, i18n.t('RegistryScreens.errors.cashboxesListNotFound')),
                        ),
                    ),
                ),
            );
        }),
    );
};
