import {
    DocumentsStatDto,
    OperatorCashBoxesStatDto,
    DocsCountsStat,
    DevicesCountsByStatuses,
} from '../api/dashBoard/dashBoardClient.typings';
import { CashBoxesStatDto } from '../api/dashBoard/dashBoardClient.typings';
import { createAction } from '../../utils/redux-utils';
import { Notification } from '../../utils/utils';
import { TechChartType } from '../../components/DashboardWitgets/Common/ChartsTyping';

export enum DashBoardTechActionType {
    OPEN_DASHBOARD_TECH_PAGE = 'DashBoardTech/ open page',
    EXIT_DASHBOARD_TECH_PAGE = 'DashBoardTech/ exit page',

    FETCH_DOCUMENTS_STAT_SUCCESS = 'DashBoardTech/ fetch documents stat success',
    FETCH_CASH_BOXES_STAT_SUCCESS = 'DashBoardTech/ fetch cashboxes stat success',
    FETCH_OPERATORS_CASH_BOXES_SUCCESS = 'DashBoardTech/ fetch operators cashBoxes success',
    FETCH_DOCS_COUNTS_SUCCESS = 'DashBoardTech/ fetch docs counts success',
    FETCH_DEVICE_COUNTS_SUCCESS = 'DashBoardTech/ fetch device counts success',

    FETCH_DOCUMENTS_STAT_ERROR = 'DashBoardTech/ fetch documents stat error',
    FETCH_CASH_BOXES_STAT_ERROR = 'DashBoardTech/ fetch cashboxes stat error',
    FETCH_OPERATORS_CASH_BOXES_ERROR = 'DashBoardTech/ fetch operators cashBoxes error',
    FETCH_DOCS_COUNTS_ERROR = 'DashBoardTech/ fetch docs counts error',
    FETCH_DEVICE_COUNTS_ERROR = 'DashBoardTech/ fetch device counts error',

    DOWNLOAD_EXCEL = 'DashBoardTech/ download data as excel',
    DOWNLOAD_EXCEL_SUCCESS = 'DashBoardTech/ download data as excel success',
    DOWNLOAD_EXCEL_ERROR = 'DashBoardTech/ download data as excel error',
}

export const openDashBoardTechPage = () => createAction(DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE);

export const fetchDocumentsSuccessAction = (data: DocumentsStatDto[]) =>
    createAction(DashBoardTechActionType.FETCH_DOCUMENTS_STAT_SUCCESS, data);

export const fetchCashBoxesStatSuccessAction = (data: CashBoxesStatDto) =>
    createAction(DashBoardTechActionType.FETCH_CASH_BOXES_STAT_SUCCESS, data);

export const fetchOperatorsCashBoxesSuccessAction = (data: OperatorCashBoxesStatDto[]) =>
    createAction(DashBoardTechActionType.FETCH_OPERATORS_CASH_BOXES_SUCCESS, data);

export const fetchDocsCountsSuccessAction = (data: DocsCountsStat) =>
    createAction(DashBoardTechActionType.FETCH_DOCS_COUNTS_SUCCESS, data);

export const fetchDeviceCountsSuccessAction = (data: DevicesCountsByStatuses) =>
    createAction(DashBoardTechActionType.FETCH_DEVICE_COUNTS_SUCCESS, data);

export const fetchDocumentsErrorAction = (notification: Notification) =>
    createAction(DashBoardTechActionType.FETCH_DOCUMENTS_STAT_ERROR, { notification });

export const fetchCashBoxesStatErrorAction = (notification: Notification) =>
    createAction(DashBoardTechActionType.FETCH_CASH_BOXES_STAT_ERROR, { notification });

export const fetchOperatorsCashBoxesErrorAction = (notification: Notification) =>
    createAction(DashBoardTechActionType.FETCH_OPERATORS_CASH_BOXES_ERROR, { notification });

export const fetchDocsCountsErrorAction = (notification: Notification) =>
    createAction(DashBoardTechActionType.FETCH_DOCS_COUNTS_ERROR, { notification });

export const fetchDeviceCountsErrorAction = (notification: Notification) =>
    createAction(DashBoardTechActionType.FETCH_DEVICE_COUNTS_ERROR, { notification });

export const exitDashBoardTechPage = () => createAction(DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE);

export const downloadTechChartDataAsExcelAction = <T>(dataType: TechChartType, params?: T) =>
    createAction(DashBoardTechActionType.DOWNLOAD_EXCEL, { dataType, params });

export const downloadTechChartDataAsExcelSuccessAction = (dataType: TechChartType) =>
    createAction(DashBoardTechActionType.DOWNLOAD_EXCEL_SUCCESS, dataType);

export const downloadTechChartDataAsExcelErrorAction = (notification: Notification, dataType: TechChartType) =>
    createAction(DashBoardTechActionType.DOWNLOAD_EXCEL_ERROR, { notification, dataType });

export type DashBoardTechAction =
    | ReturnType<typeof openDashBoardTechPage>
    | ReturnType<typeof exitDashBoardTechPage>
    | ReturnType<typeof fetchDocumentsSuccessAction>
    | ReturnType<typeof fetchCashBoxesStatSuccessAction>
    | ReturnType<typeof fetchOperatorsCashBoxesSuccessAction>
    | ReturnType<typeof fetchDocsCountsSuccessAction>
    | ReturnType<typeof fetchDeviceCountsSuccessAction>
    | ReturnType<typeof fetchDocumentsErrorAction>
    | ReturnType<typeof fetchCashBoxesStatErrorAction>
    | ReturnType<typeof fetchOperatorsCashBoxesErrorAction>
    | ReturnType<typeof fetchDocsCountsErrorAction>
    | ReturnType<typeof fetchDeviceCountsErrorAction>
    | ReturnType<typeof downloadTechChartDataAsExcelAction>
    | ReturnType<typeof downloadTechChartDataAsExcelSuccessAction>
    | ReturnType<typeof downloadTechChartDataAsExcelErrorAction>;
