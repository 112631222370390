import { createAction } from '../../utils/redux-utils';
import { Notification } from '../../utils/utils';
import { Viewport } from '../../components/Map/typings';
import { SearchQueryParams } from '../api/apiCommon.typings';
import { CashBoxesMap } from '../api/cashBoxes/cashBoxesClient.typings';

export enum CashBoxesMapActionType {
    OPEN_CASH_BOXES_MAP_PAGE = 'CashBoxes map/ open page',
    CLOSE_CASH_BOXES_MAP_PAGE = 'CashBoxes map/ close page',

    APPLY_FILTERS = 'CashBoxes map/ apply filters',
    CHANGE_FILTERS = 'CashBoxes map/ change filters',
    CASH_BOXES_VIEWPORT_CHANGE = 'CashBoxes map / viewport change',

    FETCH_CASH_BOXES_SUCCESS = 'CashBoxes map / fetch cashBoxes success',
    FETCH_CASH_BOXES_ERROR = 'CashBoxes map / fetch cashBoxes error',
}

export const openCashBoxesMapPageAction = () => createAction(CashBoxesMapActionType.OPEN_CASH_BOXES_MAP_PAGE);

export const closeCashBoxesMapPageAction = () => createAction(CashBoxesMapActionType.CLOSE_CASH_BOXES_MAP_PAGE);

export const applyFilteringAction = () => createAction(CashBoxesMapActionType.APPLY_FILTERS);

export const changeFiltersAction = (params: SearchQueryParams) =>
    createAction(CashBoxesMapActionType.CHANGE_FILTERS, params);

export const cashBoxesViewportChangeActon = (viewport: Viewport) =>
    createAction(CashBoxesMapActionType.CASH_BOXES_VIEWPORT_CHANGE, viewport);

export const fetchCashBoxesSuccessAction = (cashBoxes: CashBoxesMap) =>
    createAction(CashBoxesMapActionType.FETCH_CASH_BOXES_SUCCESS, cashBoxes);

export const fetchCashBoxesErrorAction = (notification: Notification) =>
    createAction(CashBoxesMapActionType.FETCH_CASH_BOXES_ERROR, { notification });

export type CashBoxesMapAction =
    | ReturnType<typeof openCashBoxesMapPageAction>
    | ReturnType<typeof closeCashBoxesMapPageAction>
    | ReturnType<typeof applyFilteringAction>
    | ReturnType<typeof changeFiltersAction>
    | ReturnType<typeof cashBoxesViewportChangeActon>
    | ReturnType<typeof fetchCashBoxesSuccessAction>
    | ReturnType<typeof fetchCashBoxesErrorAction>;
