import { Epic, ofType } from 'redux-observable';
import { catchError, exhaustMap, map, mapTo, switchMap, tap, takeUntil } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AxiosError } from 'axios';
import { downloadDataAsFile, parseError } from '../../utils/utils';
import { i18n } from '../translation/i18n';
import { RootState } from '../store.typings';
import {
    DashBoardTechAction,
    DashBoardTechActionType,
    fetchDocumentsSuccessAction,
    fetchCashBoxesStatSuccessAction,
    fetchOperatorsCashBoxesSuccessAction,
    fetchDocsCountsSuccessAction,
    fetchDeviceCountsSuccessAction,
    downloadTechChartDataAsExcelSuccessAction,
    downloadTechChartDataAsExcelErrorAction,
    fetchDocumentsErrorAction,
    fetchCashBoxesStatErrorAction,
    fetchOperatorsCashBoxesErrorAction,
    fetchDocsCountsErrorAction,
    fetchDeviceCountsErrorAction,
} from '../actions/dashBoardTechActions';
import { dashBoardClient } from '../api/dashBoard/dashBoardClient';
import { RegInspectorDashBoardActionType } from '../actions/RegInspectorAcc/RegInspectorDashBoardActions';
import { TechChartsFilesMap } from '../../components/DashboardWitgets/Common/ChartsTyping';
import { CentralInspectorDashBoardActionType } from '../actions/CentralInspectorAcc/CentralInspectorDashBoard';

type DashBoardTechEpic = Epic<DashBoardTechAction, DashBoardTechAction, RootState>;

export const fetchDocumentsStatEpic: DashBoardTechEpic = (action$) => {
    return action$.pipe(
        ofType(
            DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE,
            CentralInspectorDashBoardActionType.OPEN_CENTRAL_INSPECTOR_DASHBOARD_PAGE,
        ),
        switchMap(() => {
            return from(dashBoardClient.getDocumentTypesStat()).pipe(
                map((data) => fetchDocumentsSuccessAction(data)),

                catchError((err: AxiosError) =>
                    of(
                        fetchDocumentsErrorAction(
                            parseError(err, i18n.t('DashboardScreens.errors.failedLoadDocsStat')),
                        ),
                    ),
                ),

                takeUntil(
                    action$.ofType(
                        DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE,
                        CentralInspectorDashBoardActionType.EXIT_CENTRAL_INSPECTOR_DASHBOARD_PAGE,
                    ),
                ),
            );
        }),
    );
};

export const cashBoxesStatEpic: DashBoardTechEpic = (action$) => {
    return action$.pipe(
        ofType(
            DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE,
            RegInspectorDashBoardActionType.OPEN_REG_INSPECTOR_DASHBOARD,
            CentralInspectorDashBoardActionType.OPEN_CENTRAL_INSPECTOR_DASHBOARD_PAGE,
        ),
        switchMap(() => {
            return from(dashBoardClient.getCashBoxesStat()).pipe(
                map((data) => fetchCashBoxesStatSuccessAction(data)),

                catchError((err: AxiosError) =>
                    of(
                        fetchCashBoxesStatErrorAction(
                            parseError(err, i18n.t('DashboardScreens.errors.failedToLoadCashboxStat')),
                        ),
                    ),
                ),

                takeUntil(
                    action$.ofType(
                        DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE,
                        CentralInspectorDashBoardActionType.EXIT_CENTRAL_INSPECTOR_DASHBOARD_PAGE,
                    ),
                ),
            );
        }),
    );
};

export const operatorsCashBoxesEpic: DashBoardTechEpic = (action$) => {
    return action$.pipe(
        ofType(DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE),
        switchMap(() => {
            return from(dashBoardClient.getOperatorsCashBoxesStat()).pipe(
                map((data) => fetchOperatorsCashBoxesSuccessAction(data)),

                catchError((err: AxiosError) =>
                    of(
                        fetchOperatorsCashBoxesErrorAction(
                            parseError(err, i18n.t('DashboardScreens.errors.failedToLoadOperatorCashBoxesStat')),
                        ),
                    ),
                ),

                takeUntil(action$.ofType(DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE)),
            );
        }),
    );
};

export const docsCountsStatEpic: DashBoardTechEpic = (action$) => {
    return action$.pipe(
        ofType(DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE),
        switchMap(() => {
            return from(dashBoardClient.getDocumentsCounter()).pipe(
                map((data) => fetchDocsCountsSuccessAction(data)),

                catchError((err: AxiosError) =>
                    of(
                        fetchDocsCountsErrorAction(
                            parseError(err, i18n.t('DashboardScreens.errors.failedLoadDocsCountsStat')),
                        ),
                    ),
                ),

                takeUntil(action$.ofType(DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE)),
            );
        }),
    );
};

export const devicesCountsEpic: DashBoardTechEpic = (action$) => {
    return action$.pipe(
        ofType(DashBoardTechActionType.OPEN_DASHBOARD_TECH_PAGE),
        switchMap(() => {
            return from(dashBoardClient.getDeviceStatusesStat()).pipe(
                map((data) => fetchDeviceCountsSuccessAction(data)),

                catchError((err: AxiosError) =>
                    of(
                        fetchDeviceCountsErrorAction(
                            parseError(err, i18n.t('DashboardScreens.errors.failedToLoadDevicesCountsStat')),
                        ),
                    ),
                ),

                takeUntil(action$.ofType(DashBoardTechActionType.EXIT_DASHBOARD_TECH_PAGE)),
            );
        }),
    );
};

export const techChartsDataDownloadEpic: DashBoardTechEpic = (action$) => {
    return action$.pipe(
        ofType(DashBoardTechActionType.DOWNLOAD_EXCEL),
        exhaustMap((action) => {
            const fileName = TechChartsFilesMap[action.payload.dataType].fileName;

            return from(dashBoardClient.downloadTechChartData(action.payload.dataType, action.payload.params)).pipe(
                tap((fileData) => downloadDataAsFile(fileName, fileData)),
                mapTo(downloadTechChartDataAsExcelSuccessAction(action.payload.dataType)),

                catchError((err: AxiosError) =>
                    of(
                        downloadTechChartDataAsExcelErrorAction(
                            parseError(err, i18n.t('errors.failedUploadFileName', { fileName })),
                            action.payload.dataType,
                        ),
                    ),
                ),
            );
        }),
    );
};
